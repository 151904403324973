/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core'
import { isString } from '../utils/core.utils'

@Directive({
    selector: '[dynamicList]',
    standalone: false
})
export class DynamicListDirective implements AfterViewInit {
  @Input('dynamicList') listId: string | null

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    if (isString(this.listId)) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'list', this.listId as string)
    }
  }
}
