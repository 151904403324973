/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, Inject, Input } from '@angular/core'
import { Select } from '@ngxs/store'
import { ConfirmationSelectors } from '../../state/confirmation/confirmation.selectors'
import { Observable } from 'rxjs'
import { SignupService } from '../../services/signup/signup.service'
import { ConfigService } from '../../services/config/config.service'
import { PidxGoogleTagManagerIntegrationService } from '../../services/google-tag-manager/pidx-google-tag-manager-integration.service'
import { ConfirmationStep } from '../../state/confirmation-step.enum'
import { ConfirmationType } from '../../enums/confirmation-type'
import { WINDOW } from '../../services/utils'

@Component({
    selector: 'myprofile-signup-ui-cancel-activation',
    templateUrl: './cancel-activation.component.html',
    styleUrls: ['./cancel-activation.component.css'],
    standalone: false
})
export class CancelActivationComponent {
  @Input() currentPageName: string

  @Select(ConfirmationSelectors.currentStep) public readonly currentStep$: Observable<ConfirmationStep>
  @Select(ConfirmationSelectors.type) public readonly type$: Observable<ConfirmationType>

  public readonly ConfirmationSteps = ConfirmationStep

  public currentEventAction: string
  public showModal = false

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _signupService: SignupService,
    private _tagManagerIntegrationService: PidxGoogleTagManagerIntegrationService,
    private _configService: ConfigService,
  ) {}

  get modalHeader() {
    if (this._signupService.transactionData.type !== 'VEHICLE' && this._signupService.transactionData.type !== 'SECONDARY_VEHICLE') {
      return 'signup.activateAccount.cancelActivation.modal.title'
    }
    return 'pida.addVehicle.cancelActivation.modal.title'
  }

  public showCancelActivationModal() {
    this._tagManagerIntegrationService.triggerClickTracking({
      clickElementType: 'textlink',
      clickElementId: 'cancellation_option',
      clickElementName: 'open_modal',
      pageName: this.currentPageName,
      eventAction: this.currentEventAction,
    })
    this.showModal = true
  }

  public toggleModal(elementName: string) {
    this.showModal = !this.showModal
    this._tagManagerIntegrationService.triggerClickTracking({
      clickElementType: 'button',
      clickElementId: 'cancellation_modal',
      clickElementName: elementName,
      pageName: this.currentPageName,
      eventAction: this.currentEventAction,
    })
  }

  public cancelActivation(): void {
    this._tagManagerIntegrationService.triggerClickTracking({
      clickElementType: 'button',
      clickElementId: 'cancellation_modal',
      clickElementName: 'cancel_activation',
      pageName: this.currentPageName,
      eventAction: this.currentEventAction,
    })
    if (
      this._signupService.transactionType === 'SIMPLIFIED_ADD_NON_CONNECT_VEHICLE' ||
      this._signupService.transactionType === 'SIMPLIFIED_ADD_CONNECT_VEHICLE'
    ) {
      this._window.location.href = this._configService.getExternalUrl('portalAddVehicle')
    } else {
      this._window.location.href = this._configService.getExternalUrl('myPorscheHome')
    }
  }
}
