/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { DOCUMENT } from '@angular/common'
import { BaseFormControlComponent } from '../base-form-control.component'
import { DynamicFormLayout, DynamicFormLayoutService } from '../../../service/dynamic-form-layout.service'
import { DynamicFormControlLayout } from '../../../model/misc/dynamic-form-control-layout.model'
import { DynamicInputModel } from '../../../model/input/dynamic-input.model'
import { DynamicFormValidationService } from '../../../service/dynamic-form-validation.service'
import { PasswordStrengthMeterComponent } from './password-strength-meter/password-strength-meter.component'

@Component({
    selector: 'dynamic-basic-input',
    templateUrl: './dynamic-basic-input.component.html',
    standalone: false
})
export class DynamicBasicInputComponent extends BaseFormControlComponent implements AfterViewInit {
  @Input() formLayout: DynamicFormLayout
  @Input() layout: DynamicFormControlLayout
  @Input() model: DynamicInputModel
  @Input() group: UntypedFormGroup

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<any> = new EventEmitter()

  @ViewChild('strengthMeterViewContainerRef', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef

  public passwordValue: string

  // -------------------------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -------------------------------------------------------------------------------------------------------------------
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public layoutService: DynamicFormLayoutService,
    public validationService: DynamicFormValidationService,
    private _componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super(layoutService, validationService)
  }

  // -------------------------------------------------------------------------------------------------------------------
  // @ LIFECYCLE-METHODS
  // -------------------------------------------------------------------------------------------------------------------
  public ngAfterViewInit() {
    // We just instantiate and mount the password strength meter in case the schema tells us to
    if (this.model.inputType === 'password' && this.model.displayStrengthMeter) {
      const strengthMeterComponentFactory = this._componentFactoryResolver.resolveComponentFactory(PasswordStrengthMeterComponent)
      const componentRef = this.viewContainerRef.createComponent(strengthMeterComponentFactory)

      // get the password and pass it to the strength meter
      this.group.get(this.model.id).valueChanges.subscribe((data) => {
        componentRef.instance.password = data
      })

      this.focus.subscribe(() => {
        componentRef.instance.displayPasswordCriteria()
      })
    }
  }
}
