/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { Layout, LayoutComponent } from './layout/layout.component'
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component'
import { SetupGuardService } from './services/setup-guard/setup-guard.service'

const routes: Routes = [
  {
    path: 'auth/callback',
    pathMatch: 'full',
    component: AuthCallbackComponent,
  },
  {
    path: ':country/:locale',
    canActivate: [SetupGuardService],
    children: [
      {
        path: 'register',
        component: LayoutComponent,
        data: { layout: Layout.PROSPECT_LAYOUT },
        loadChildren: () =>
          import('./pages/signup-prospect-and-org/signup-prospect-and-org.module').then((mod) => mod.SignupProspectAndOrgModule),
      },
      {
        path: 'confirm',
        loadChildren: () => import('./pages/confirmation/confirmation.module').then((mod) => mod.ConfirmationModule),
      },
      {
        path: 'l',
        component: LayoutComponent,
        data: { layout: Layout.DEFAULT_LAYOUT },
        loadChildren: () => import('./pages/short-link/short-link.module').then((mod) => mod.ShortLinkModule),
      },
      {
        path: 'error',
        component: LayoutComponent,
        data: { layout: Layout.DEFAULT_LAYOUT },
        loadChildren: () => import('./pages/error-page/error-page.module').then((mod) => mod.ErrorPageModule),
      },
      {
        path: 'blocked',
        component: LayoutComponent,
        data: { layout: Layout.DEFAULT_LAYOUT },
        loadChildren: () => import('./pages/blocked/blocked-routing').then((mod) => mod.BLOCKED_ROUTES),
      },
      {
        path: 'maintenance',
        component: LayoutComponent,
        data: { layout: Layout.MAINTENANCE_LAYOUT },
        loadChildren: () => import('./pages/maintenance/maintenance-routing').then((mod) => mod.MAINTENANCE_ROUTES),
      },
      {
        path: '**',
        redirectTo: 'register',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/de/de_DE/register',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
