/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Directive, ElementRef, Input, OnDestroy } from '@angular/core'
import { PidxGoogleTagManagerIntegrationService } from '../services/google-tag-manager/pidx-google-tag-manager-integration.service'

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[myprofileSignupUiValidationTrack]',
    standalone: false
})
export class PidxFormValidationTrackingDirective implements OnDestroy {
  @Input('myprofileSignupUiValidationTrack') formName: string
  private inputFields: any[]

  constructor(private _elementRef: ElementRef, private _googleTagManagerIntegrationService: PidxGoogleTagManagerIntegrationService) {
    this.addListener()
  }

  public addListener() {
    this.clearListener()

    setTimeout(() => {
      this._googleTagManagerIntegrationService.setupValidationError(this.formName)
      this.inputFields = this._elementRef.nativeElement.querySelectorAll('input')
      const selectFields = this._elementRef.nativeElement.querySelectorAll('select')
      selectFields.forEach((element) => {
        this.inputFields = [...this.inputFields, element]
      })

      this.inputFields.forEach((element) => {
        element.addEventListener('blur', this.googleEventListener.bind(this))
      })
    })
  }

  public clearListener() {
    this._googleTagManagerIntegrationService.clearValidationErrors(this.formName)
    this.inputFields?.forEach((element) => {
      element.removeEventListener('blur', this.googleEventListener)
    })
  }

  public googleEventListener($event) {
    setTimeout(() => {
      const closestFormControlName = $event.target.closest('[formcontrolname]')
      const formControlName = closestFormControlName
        ? closestFormControlName.getAttribute('formcontrolname')
        : $event.target.getAttribute('name')

      if ($event.target.classList.contains('ng-invalid')) {
        this._googleTagManagerIntegrationService.addValidationError(this.formName, formControlName)
      } else {
        this._googleTagManagerIntegrationService.removeValidationError(this.formName, formControlName)
      }
    })
  }

  public ngOnDestroy() {
    this.clearListener()
  }
}
