/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core'
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular'
import { TranslateModule } from '@ngx-translate/core'
import { ConfigService } from '../../services/config/config.service'
import { FormsModule } from '@angular/forms'
import { ConfigLoaderService } from '../../services/config/config-loader.service'
import { localisedLanguageNames } from './localized-language-names'

@Component({
    selector: 'myprofile-signup-ui-footer',
    templateUrl: './footer.component.html',
    imports: [PorscheDesignSystemModule, TranslateModule, FormsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FooterComponent {
  @Input() showFooterLinksChina = false
  @Input() showFooterLinksUS = false
  @Input() footerLinks: SignupFooterLink[]
  @Input() languageChooserEnabled = false
  @Output() languageChanged: EventEmitter<string> = new EventEmitter<string>()

  year = new Date().getFullYear()
  currentLanguage: string = undefined
  currentCountry: string = undefined
  availableLanguages: { value: any; title: any }[] = []

  constructor(private configService: ConfigService, private configLoaderService: ConfigLoaderService) {
    this.currentCountry = this.configService.getUrlCountry()
    this.currentLanguage = this.configService.getUrlLocale()
    this.init()
  }

  async init() {
    const countryLocaleMap = await this.configLoaderService.getCountryLocaleMap()
    countryLocaleMap[this.currentCountry].locales.forEach((language) => {
      this.availableLanguages.push({
        value: language,
        title: localisedLanguageNames[language.split('_')[0]][this.currentLanguage.split('_')[0]],
      })
    })
  }
}

export interface SignupFooterLink {
  title?: string
  url?: string
  key?: string
}
