/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { BaseFormControlComponent } from '../base-form-control.component'
import { DynamicFormLayout, DynamicFormLayoutService } from '../../../service/dynamic-form-layout.service'
import { DynamicFormControlLayout } from '../../../model/misc/dynamic-form-control-layout.model'
import { DynamicRadioGroupModel } from '../../../model/radio/dynamic-radio-group.model'
import { DynamicFormValidationService } from '../../../service/dynamic-form-validation.service'

@Component({
    selector: 'dynamic-basic-radio-group',
    templateUrl: './dynamic-basic-radio-group.component.html',
    styleUrls: ['./dynamic-basic-radio-group.component.scss'],
    standalone: false
})
export class DynamicBasicRadioGroupComponent extends BaseFormControlComponent {
  @Input() formLayout: DynamicFormLayout
  @Input() group: UntypedFormGroup
  @Input() layout: DynamicFormControlLayout
  @Input() model: DynamicRadioGroupModel<string>

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<any> = new EventEmitter()

  constructor(
    public layoutService: DynamicFormLayoutService,
    public validationService: DynamicFormValidationService,
  ) {
    super(layoutService, validationService)
  }
}
