/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component } from '@angular/core'
import { DynamicFormLayoutService } from '../../../service/dynamic-form-layout.service'
import { DynamicFormValidationService } from '../../../service/dynamic-form-validation.service'
import { BaseFormControlComponent } from '../base-form-control.component'

@Component({
    selector: 'dynamic-basic-paragraph',
    templateUrl: './dynamic-basic-paragraph.component.html',
    styles: [':host { display: block;}'],
    standalone: false
})
export class DynamicBasicParagraphComponent extends BaseFormControlComponent {
  constructor(
    public layoutService: DynamicFormLayoutService,
    public validationService: DynamicFormValidationService,
  ) {
    super(layoutService, validationService)
  }
}
