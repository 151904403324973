/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { TranslateParser } from '@ngx-translate/core'
import { WINDOW } from '../../services/utils'

@Component({
    selector: 'myprofile-signup-ui-timer-label',
    templateUrl: './timer-label.component.html',
    standalone: false
})
export class TimerLabelComponent implements OnInit {
  @Input() waitingTimerLabelText: string
  @Input() requestCodeTimerLabel: string
  @Input() inactiveTimerButton: boolean
  @Input() timer = 30

  @Output() clickedTimerEvent = new EventEmitter<void>()

  public isEnabled = false

  private _timeRemaining = 30
  protected set timeRemaining(newValue: any) {
    this._timeRemaining = newValue
  }

  protected get timeRemaining() {
    return { TIME_REMAINING: this._timeRemaining }
  }

  protected counter: any
  protected timerLabel: string

  public currentTime: number

  constructor(@Inject(WINDOW) protected window: Window, private translateParser: TranslateParser) {}

  ngOnInit() {
    this.currentTime = this.timer
    this.start()
  }

  public clickHandler() {
    this.isEnabled = false
    this.clickedTimerEvent.emit()
    this.timeRemaining = this.currentTime
    this.start()
  }

  private updateTimerLabel() {
    this.timerLabel = this.translateParser.interpolate(this.waitingTimerLabelText, this.timeRemaining)
  }

  private start() {
    this.updateTimerLabel()
    this.currentTime--

    this.counter = this.window.setInterval(() => {
      this.timeRemaining = this.currentTime
      this.updateTimerLabel()

      this.currentTime--

      if (this.currentTime === -1) {
        clearInterval(this.counter)
        this.counter = undefined
        this.isEnabled = true

        this.currentTime = this.timer
      }
    }, 1000)
  }
}
