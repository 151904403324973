/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, OnInit } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { Location } from '@angular/common'
import { parseUrl } from '../../services/utils'
import { Router, RouterStateSnapshot } from '@angular/router'

/**
 * This Component is necessary for Auth0 to process
 * code and state in order to retrieve an actual token
 */
@Component({
    template: '',
    standalone: false
})
export class AuthCallbackComponent implements OnInit {
  private state: RouterStateSnapshot

  constructor(private _authService: AuthService, private _location: Location, private _router: Router) {
    this.state = this._router.routerState.snapshot
  }

  ngOnInit() {
    this._authService.appState$.subscribe((state) => {
      const target = state.target
      const parsedURL = parseUrl(this.state.url, `../../${target}`)
      this._location.go(parsedURL)
    })
  }
}
