/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, ViewChild } from '@angular/core'
import { Location } from '@angular/common'
import { TransactionType } from '../../../enums/transaction-type'
import { Select, Store } from '@ngxs/store'
import { ConfirmationSelectors } from '../../../state/confirmation/confirmation.selectors'
import { lastValueFrom, Observable, Subject } from 'rxjs'
import { SignupService } from '../../../services/signup/signup.service'
import { ConfigService } from '../../../services/config/config.service'
import { UntypedFormGroup } from '@angular/forms'
import { PidxGoogleTagManagerIntegrationService } from '../../../services/google-tag-manager/pidx-google-tag-manager-integration.service'
import { Confirmation } from '../../../state/confirmation/confirmation.actions'
import { ConfirmationCodeStatus } from '../../../enums/confirmation-code-status'
import { TranslateService } from '@ngx-translate/core'
import { filter } from 'rxjs/operators'
import { RegistrationProcess } from '../../../enums/registration-process'
import { Router, RouterStateSnapshot } from '@angular/router'
import { parseUrl, setPinCodeFocus, WINDOW } from '../../../services/utils'
import { PinCodeLength, PinCodeState, PinCodeUpdateEventDetail, ToastManager } from '@porsche-design-system/components-angular'
import ValidateOTP = Confirmation.ValidateOTP
import RequestOTP = Confirmation.RequestOTP
import { ConfirmationStep } from '../../../state/confirmation-step.enum'

@Component({
    selector: 'myprofile-signup-ui-sms-confirm-identity',
    templateUrl: './sms-confirm-identity.component.html',
    standalone: false
})
export class SmsConfirmIdentityComponent implements AfterViewInit, OnDestroy {
  @Input() mobile: string
  @Input() currentPageName: string
  @Input() messages: string[]
  @Input() messageState: PinCodeState
  @Input() mode: RegistrationProcess = RegistrationProcess.OWNER

  @ViewChild('smsCodeOwner', { read: ElementRef }) smsCodeOwnerComponent!: ElementRef
  @ViewChild('smsCodeProspect', { read: ElementRef }) smsCodeProspectComponent!: ElementRef

  @Select(ConfirmationSelectors.transactionType) public readonly transactionType$: Observable<TransactionType>
  @Select(ConfirmationSelectors.codeStatus) public readonly codeStatus$: Observable<ConfirmationCodeStatus>
  @Select(ConfirmationSelectors.confirmationLoading) public readonly confirmationLoading$: Observable<boolean>
  @Select(ConfirmationSelectors.otpRequested) public readonly otpRequested$: Observable<boolean>
  @Select(ConfirmationSelectors.otpRequestBlocked) public readonly otpRequestedBlocked$: Observable<boolean>
  @Select(ConfirmationSelectors.currentStep) public readonly currentStep$: Observable<ConfirmationStep>

  public readonly TransactionType = TransactionType
  public RegistrationProcess = RegistrationProcess
  public readonly ConfirmationCodeStatus = ConfirmationCodeStatus
  codeStatus: ConfirmationCodeStatus
  public otpRequested = false
  public openBanner = false
  public otpLength: PinCodeLength = 5

  public formGroup = new UntypedFormGroup({})

  private state: RouterStateSnapshot
  private _unsubscribe$ = new Subject<void>()

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _signupService: SignupService,
    private _tagManagerIntegrationService: PidxGoogleTagManagerIntegrationService,
    private readonly store: Store,
    private _translateService: TranslateService,
    private _configService: ConfigService,
    private _router: Router,
    private _location: Location,
    private _toastManager: ToastManager,
  ) {
    this.state = this._router.routerState.snapshot
    this.codeStatus$.pipe(filter((status) => status === ConfirmationCodeStatus.CODE_STATUS_INVALID)).subscribe(() => {
      this.messages = [this._translateService.instant('signup.generic.error.confirmationCodeIsInvalid')]
      this.messageState = 'error'
      this._tagManagerIntegrationService.triggerLoadTracking({
        pageName: 'sign_up/identityverification',
        eventAction: 'PAGMyPorsche_SignUpIdentityVerificationError_Load',
      })
    })

    this.otpRequestedBlocked$.subscribe((blocked) => {
      if (blocked) {
        this.openBanner = true
        setTimeout(() => (this.openBanner = false), 8000)
      }
    })
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next()
    this._unsubscribe$.complete()
  }

  ngAfterViewInit(): void {
    this._window.setTimeout(() => {
      if (this.mode === RegistrationProcess.OWNER) {
        setPinCodeFocus(this.smsCodeOwnerComponent.nativeElement.shadowRoot)
      } else {
        setPinCodeFocus(this.smsCodeProspectComponent.nativeElement.shadowRoot)
      }
    }, 100)
  }

  goToManualAddVehicleProcess() {
    const { vehicle } = this._signupService.transactionData
    this._window.location.href = this._configService.getExternalUrl('portalAddVehicle') + '/' + vehicle.vin
  }

  public async onAutoSubmitOTP(e: CustomEvent<PinCodeUpdateEventDetail>, mode: RegistrationProcess) {
    const isComplete = e.detail.isComplete
    if (!isComplete) return

    //PROFILE-10215: Pin-Code component has randomly has a leading whitespace in its value, that's why we remove it
    const otp = e.detail.value.trim()

    if (otp.length == this.otpLength) {
      if (mode === RegistrationProcess.OWNER) {
        this.autoSubmitOwnerOTP(otp)
        // remove the focus of the last input
        this.smsCodeOwnerComponent.nativeElement.blur()
      } else {
        await this.autoSubmitProspectOTP(otp)
        // remove the focus of the last input
        this.smsCodeProspectComponent.nativeElement.blur()
      }
    }
  }

  private autoSubmitOwnerOTP(confirmationCode: string) {
    this.store.dispatch(new ValidateOTP(confirmationCode))
  }

  private async autoSubmitProspectOTP(confirmationCode: string) {
    try {
      this.codeStatus = ConfirmationCodeStatus.CODE_STATUS_VALIDATION_PENDING
      const response = await lastValueFrom(
        this._signupService.verifyOTPAndGetTransactionId(this.mobile, confirmationCode, TransactionType.PROSPECT),
      )
      this.codeStatus = ConfirmationCodeStatus.CODE_STATUS_VALID
      setTimeout(async () => this.navigateToConfirmation(response.tid, confirmationCode), 500)
    } catch (e) {
      console.log(e)
      if (e.status === 409 && e.error?.errorCode === 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_TEMPORARILY_BLOCKED') {
        this.codeStatus = ConfirmationCodeStatus.CODE_STATUS_TEMPORARILY_BLOCKED
        this.messages = [this._translateService.instant('signup.generic.error.sms.confirmationCodeIsTemporarilyBlocked')]
      } else {
        this.codeStatus = ConfirmationCodeStatus.CODE_STATUS_INVALID
        this.messages = [this._translateService.instant('signup.generic.error.confirmationCodeIsInvalid')]
      }
      this.messageState = 'error'
    }
  }

  public onRequestOTP(resend = false) {
    this.store.dispatch(new RequestOTP(resend))
  }

  public async onRequestProspectOTP() {
    try {
      this.otpRequested = true
      await lastValueFrom(this._signupService.resendRegistrationByPorscheId(this.mobile, 'SMS'))
      const toast = this._translateService.instant('signup.register.prospect.verifySms.resendSuccessNotification')
      this._toastManager.addMessage({ state: 'success', text: toast })
      setTimeout(() => {
        this.otpRequested = false
      }, 3000)
    } catch (e) {
      if (
        (e.status === 409 && e.error?.errorCode === 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_TEMPORARILY_BLOCKED') ||
        (e.status === 429 && e.error?.errorCode === 'TOO_MANY_REQUESTS_OTP') ||
        (e.status === 404 && e.error?.errorCode === 'RESOURCE_NOT_FOUND_OPEN_INVITATIONS')
      ) {
        this.codeStatus = ConfirmationCodeStatus.CODE_STATUS_TEMPORARILY_BLOCKED
        this.openBanner = true
        setTimeout(() => (this.openBanner = false), 8000)
      }
      this.otpRequested = false
    }
  }

  private async navigateToConfirmation(tid: string, otp: string) {
    const target = `confirm/${tid}/${otp}`
    const parsedURL = parseUrl(this.state.url, `../../${target}`)
    this._location.go(parsedURL)
    await this._router.navigate([parsedURL])
  }

  isCodeStatusPendingOrValid(confirmationCodeStatus: ConfirmationCodeStatus | null) {
    return (
      confirmationCodeStatus === ConfirmationCodeStatus.CODE_STATUS_VALIDATION_PENDING ||
      confirmationCodeStatus === ConfirmationCodeStatus.CODE_STATUS_VALID
    )
  }
}
