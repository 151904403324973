/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Confirmation } from '../../state/confirmation/confirmation.actions';
import ProceedConfirmation = Confirmation.ProceedConfirmation;

@Component({
    selector: 'myprofile-signup-ui-confirmation-introduction',
    templateUrl: './confirmation-introduction.component.html',
    standalone: false
})
export class ConfirmationIntroductionComponent {

  @Input() currentPageName: string
  @Input() currentEventAction: string

  constructor(private readonly store: Store) {
  }

  public onContinue() {
    this.store.dispatch(new ProceedConfirmation())
  }
}
