/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { AfterContentInit, Component, Inject } from '@angular/core'
import { ConfigService } from '../services/config/config.service'
import { ActivatedRoute, Router } from '@angular/router'
import { UntilDestroy } from '@ngneat/until-destroy'
import { PidxGoogleTagManagerIntegrationService } from '../services/google-tag-manager/pidx-google-tag-manager-integration.service'
import { GTMConfig } from '../services/google-tag-manager/pidx-google-tag-manager-integration.interfaces'
import { FullstoryService } from '../services/fullstory/fullstory.service'
import { DOCUMENT } from '@angular/common'
import { Subject, takeUntil } from 'rxjs'
import { SignupFooterLink } from '../components/footer/footer.component'

export enum Layout {
  DEFAULT_LAYOUT = 'DEFAULT_LAYOUT',
  PROSPECT_LAYOUT = 'PROSPECT_LAYOUT',
  SUCCESS_LAYOUT = 'SUCCESS_LAYOUT',
  MAINTENANCE_LAYOUT = 'MAINTENANCE_LAYOUT'
}

@UntilDestroy()
@Component({
    selector: 'myprofile-signup-ui-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    standalone: false
})
export class LayoutComponent implements AfterContentInit {
  public layout = Layout.DEFAULT_LAYOUT
  public isProd = false
  public locale = 'en-GB'
  public navMode = 'nav'
  public navTheme = 'light'
  public navLimitedWidth = true

  public get showFooterLinkChina(): boolean {
    return this._country === 'cn'
  }

  public get showFooterLinkUSA(): boolean {
    return this._country === 'us'
  }

  public get footerLinks(): SignupFooterLink[] {
    return this._configService.getFooterLinks().meta
  }

  private readonly _country: string
  private _unsubscribe$ = new Subject<void>()

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _configService: ConfigService,
    private _router: Router,
    private _googleTagManagerIntegration: PidxGoogleTagManagerIntegrationService,
    private _fullstoryService: FullstoryService,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._country = this._configService.getUrlCountry()
    this._activatedRoute.data.pipe(takeUntil(this._unsubscribe$)).subscribe(async (data) => {
      this.layout = data['layout']
    })
  }

  public ngAfterContentInit() {
    if (!this._configService.isCNDomain()) {
      this._setupGATracking()
      this._fullstoryService.setupFullstory()
    }

    if (Layout.SUCCESS_LAYOUT == this.layout || Layout.MAINTENANCE_LAYOUT == this.layout) {
      this.navMode = 'hero'
      this.navTheme = 'dark'
      this.navLimitedWidth = false
    }
  }

  private async _setupGATracking() {
    const trackingConfig: GTMConfig = {
      country: this._configService.getUrlCountry(),
      language: this._configService.getUrlLocale(),
      environment: this._configService.getGTMEnvironment(),
      applicationId: this._configService.getGTMApplicationID(),
      server: new URL(this._configService.getBaseUrl()).hostname,
    }
    await this._googleTagManagerIntegration.init(trackingConfig, this._configService.getGTMIsEnabled())
  }

  public languageChanged(selectedLocale: string) {
    // This will get the url-path after the country/locales like 'success-page'
    const routeSegmentsWithoutCountryAndLocale = this._router.url.split('/').slice(3)
    this._document.location.href = [
      this._configService.getBaseUrl(),
      this._configService.getUrlCountry(),
      selectedLocale,
      ...routeSegmentsWithoutCountryAndLocale,
    ].join('/')
  }

  protected readonly Layout = Layout
}
