/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core'
import { ConfigService } from '../../services/config/config.service'
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular'

@Component({
    selector: 'myprofile-signup-ui-navigation',
    templateUrl: './navigation.component.html',
    imports: [PorscheDesignSystemModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NavigationComponent implements OnInit {

  @Input() public navMode = 'nav'
  @Input() public navTheme = 'light'
  @Input() public navLimitedWidth = true
  public isProd = false
  public locale = 'en-GB'

  constructor(private _configService: ConfigService) {
  }

  ngOnInit(): void {
      this.isProd = this._configService.isProduction()
      this.locale = this.parseLocale()
  }

  /**
   * This function replaces the underscore with a dash, because it is the needed format for the new navigation
   * Example: de_DE -> de-DE
   */
  private parseLocale(): string {
    const urlLocale = this._configService.getUrlLocale()
    const urlCountry = this._configService.getUrlCountry()
    if (urlCountry.toLowerCase() === 'ae') {
      return 'en-middle-east'
    }
    if (urlLocale && urlCountry) {
      return `${urlLocale.substring(0, 2).toLowerCase()}-${urlCountry.toUpperCase()}`
    }
    return ''
  }


}
